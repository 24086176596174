import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn} from "@elements/dom-utils";
import { translate } from '@elements/translations';


const selectors = {
    base: '.js-show-more',
    btn: '.js-show-more__btn',
    text: '.js-show-more__text'
};
export function init() {
    onFind(selectors.base, (showMore => {
        let btn = findIn(selectors.btn, showMore);
        let text = findIn(selectors.text, showMore);
        console.log("text", text)
        console.log("btn", btn)

        if (btn && text) {
            if (showMore.getAttribute('data-show') && showMore.getAttribute('data-show') === "teaser") {

                //check if text is clamped
                if (text.scrollHeight > text.clientHeight) {
                    on("click", (evt) => {
                        evt.preventDefault();

                        if (text.classList.contains("wysiwyg--show")) {
                            text.classList.remove("wysiwyg--show");
                            btn.innerHTML = translate('app.show-more');
                        } else {
                            text.classList.add("wysiwyg--show");
                            btn.innerHTML = translate('app.show-less');
                        }
                    }, btn);
                } else {
                    btn.setAttribute("hidden", true);
                }
            } else {
                // check if text is empty
                if (text.innerHTML.trim() !== "") {
                    on("click", (evt) => {
                        evt.preventDefault();

                        if (text.classList.contains("usps__text--hide")) {
                            text.classList.remove("usps__text--hide");
                            btn.innerHTML = translate('app.show-less');
                        } else {
                            text.classList.add("usps__text--hide");
                            btn.innerHTML = translate('app.show-more');
                        }
                    }, btn);
                } else {
                    btn.setAttribute("hidden", true);
                }
            }
        }
    }))
}
