import {findIn, closest, on, findAll, addClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import Modal from "bootstrap/js/dist/modal";

export function init () {
    onFind(".js-cut-roll", function (form) {
        let parentInputId = findIn(".js-cut-roll__parent-id", form);
        let id = parentInputId.value;
        let articleItem = findAll('[data-item-id="'+id+'"]');
        let text = form.getElementsByTagName('textarea')

        articleItem.forEach(function (item) {
            let parentInput = findIn(".js-cut-roll__parent-input", item);

            if(parentInput){
                if(parentInput.getAttribute('value')){
                    text[0].setAttribute('value', parentInput.getAttribute('value'))
                    text[0].innerHTML = parentInput.getAttribute('value');
                    text[0].classList.add("has-value");

                    findIn(".js-form-validation__group", form).classList.add("has-value");
                }
            }
        });

        on('submit', function (evt) {
            evt.preventDefault();
            let input = findIn(".js-cut-roll__input", form);
            let value = input.value;

            // iterate over all article items and set values
            articleItem.forEach(function (item) {
                let parentInput = findIn(".js-cut-roll__parent-input", item);
                let parentBtn = findIn(".js-cut-roll__toggle", item);
                let parentCalc = findIn(".js-price-calc-form__result", item);

                if (parentBtn) {
                    addClass('cut-roll__btn--active', parentBtn);
                }
                if (parentInput) {
                    parentInput.setAttribute("value", value);

                    if (parentInput.classList.contains("js-watch-changes__input")){
                        parentInput.dispatchEvent(new Event("change"));
                    }

                    if (parentCalc != null){
                        parentCalc.replaceChildren();
                    }
                }
                // set text if available
                let text = findIn(".js-cut-roll__text", item);
                if (text) {
                    text.textContent = value;
                }

            });

            let myModal = Modal.getOrCreateInstance(closest('.modal', this));
            myModal.hide();
        }, form);
    });
}