import {onFind} from "@elements/init-modules-in-scope";
import {trigger, removeAttribute, setAttribute, findIn} from "@elements/dom-utils";
import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

/* only content specific JS here */
import * as googleMap from './scripts/google-map';
googleMap.init();

import * as cutRoll from './scripts/cut-roll';
cutRoll.init();

import * as multiSelectModal from './scripts/multiselect-modal';
multiSelectModal.init();

import * as amountModal from './scripts/amount-modal-calculation';
amountModal.init();

import * as productInquiry from './scripts/productInquiry';
productInquiry.init();

import * as checkAll from './scripts/check-all';
checkAll.init();

import * as hotspots from './scripts/hotspots';
hotspots.init();

import * as scrollIndicator from './scripts/scroll-indicator';
scrollIndicator.init();

import * as productGrid from './libs/@elements/productGrid';
productGrid.init({disableScroll: true});

import * as productGridQuickFilter from './libs/@elements/quickFilter';
productGridQuickFilter.init();

import * as showMore from './scripts/show-more';
showMore.init();

import * as hero from './scripts/hero';
hero.init();

import * as rangeSlider from '@elements/range-slider';
// rangeSlider.init();
onFind('.js-range-slider', function (slider) {
    rangeSlider.createRangeSlider(slider, {
        onSet: (sliderObj) => {
            let range = JSON.parse(slider.getAttribute('data-range-slider-range'));
            if (sliderObj.values[0] > range.min) {
                removeAttribute('disabled', findIn('.js-range-slider__min', slider))
            } else {
                setAttribute('disabled', true, findIn('.js-range-slider__min', slider))
            }

            if (sliderObj.values[1] < range.max) {
                removeAttribute('disabled', findIn('.js-range-slider__max', slider))
            } else {
                setAttribute('disabled', true, findIn('.js-range-slider__max', slider))
            }

            trigger('range-slider.set', slider);
        }
    }, {
        slider: '.js-range-slider__slider',
        inputMin: '.js-range-slider__min',
        inputMax: '.js-range-slider__max'
    });
});
