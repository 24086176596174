import {onFind} from "@elements/init-modules-in-scope";
import {on, closest, findIn, removeClass, removeAttribute, setAttribute, findAll} from "@elements/dom-utils";

export function init () {

    // listen to wishlist rendering (e.g. after deletion)
    on('renderd.wishlist', (evt) => {

        if (evt.detail?.activeIds?.length === 0) {
            let elementsToShow = findAll('.js-product-inquiry__show-when-empty');
            elementsToShow.map((el) => removeAttribute('hidden', el));

            let elementsToHide = findAll('.js-product-inquiry__hide-when-empty');
            elementsToHide.map((el) => setAttribute('hidden', 'hidden', el));
        }

    } , document);

}