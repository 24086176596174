import {findIn, closest, on, findAll, setAttribute} from "@elements/dom-utils";
import {initInScope,cleanUpInScope,onFind} from "@elements/init-modules-in-scope";
import Modal from "bootstrap/js/dist/modal";
import {showNotification} from '@elements/alert-notification';
import axios from "axios";

export function init () {
    onFind(".js-amount-modal-form", function (form) {
        on('submit', function (evt) {
            evt.preventDefault();

            /* set unit to hidden input */
            let units = findAll(".js-amount-modal-form__unit-selected", form);
            let unitSelected

            units.forEach((unit) => {
                if (unit.checked === true){
                    unitSelected = unit;
                }
            })

            let unitSelectedValue = unitSelected.getAttribute("data-unit");
            let unitInput = findIn(".js-amount-modal-form__unit-value", form);
            unitInput.value = unitSelectedValue;

            let pendingRequest = axios({method: 'POST', url: this.getAttribute('data-action'), data: new FormData(this) });

            showNotification({closable: true});
            showNotification(pendingRequest.then((x) => x.data));

            pendingRequest.then(({data}) => {

                if(data.success) {
                    let id = data.articleId;
                    let unit = data.unit;
                    let amount = data.amount;
                    let min = data.min;
                    let max = data.max;
                    let steps = data.step;

                    let articleItem = findAll('[data-item-id="'+id+'"]');

                    if (data.modal) {
                        setTimeout(function(){
                        /* modal fun*/
                        let myModal = document.getElementById('dynamicModal');
                        let myModalEl = Modal.getOrCreateInstance(myModal);

                        //set modal options backdrop static
                        myModalEl._config.backdrop = 'static';
                        myModalEl._config.keyboard = false;

                        let myModalContent = findIn('.js-dynamic-modal__content', myModal);
                        myModalContent.innerHTML = data.modal;
                        myModalEl.show();
                        //initInScope(myModalContent);

                        myModal.addEventListener('hidden.bs.modal', function (event) {
                            myModalEl._config.backdrop = true;
                            myModalEl._config.keyboard = true;
                        })
                        }, 500);
                    }


                    // iterate over all article items and set values
                    articleItem.forEach(function (item) {
                        let itemPriceResult = findIn(".js-price-calc-form__result", item);
                        let amountInput = findIn(".js-amount-modal__amount", item);
                        let unitInput = findIn(".js-amount-modal__unit", item);
                        let loading = findIn(".js-price-calc-form__loading", item);
                        let parentElement = item.closest('form');
                        let additionalLoading = false;

                        if (parentElement) {
                            additionalLoading = findIn(".js-price-calc-form__loading", parentElement);
                        }

                        if (amountInput) {
                            amountInput.value = amount;
                            amountInput.setAttribute("min",min);
                            amountInput.setAttribute("max",max);
                            amountInput.setAttribute("step",steps);
                        }

                        if (unitInput) {
                            unitInput.value = unit;
                        }

                        if (itemPriceResult) {
                            if (loading) {
                                setAttribute('hidden', true, loading);
                            }
                            if (additionalLoading) {
                                setAttribute('hidden', true, additionalLoading);
                            }
                            itemPriceResult.innerHTML = data.html
                            initInScope(itemPriceResult);
                        };
                    });

                }
            });

            let myModal = Modal.getOrCreateInstance(closest('.modal', this));
            myModal.hide();
        }, form);
    });
}