import * as googleMap from "@elements/google-map";
import {onFind} from "@elements/init-modules-in-scope";
import axios from "axios";

export function init () {
    let checkMapConsent = setInterval(function(){
        let consent = _config.mapConsentGiven;
        if(consent) {
            initDsgvoMap(true);
            clearInterval(checkMapConsent);
        }
    }, 250)


    function initDsgvoMap(cookieAccepted){
        onFind('.js-google-map', function (map) {
            let url = map.getAttribute('data-map-url'),
                zoom = map.getAttribute('data-map-zoom') | 15;

            let clusteringOptions = {
                default: {
                    styles: [{
                        height: 44,
                        url: "/static/img/map/poi-cluster.svg",
                        width: 44,
                        textColor: "#ffffff",
                        anchor: [0, 0]
                    }]
                },
                active: {
                    styles: [{
                        height: 44,
                        url: "/static/img/map/poi-cluster.svg",
                        width: 44,
                        textColor: "#ffffff",
                        anchor: [0, 0]
                    }]
                }
            };

            if(cookieAccepted) {
                axios({
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: url,
                    responseType: 'json'
                }).then(function (response) {
                    googleMap.init({
                        mapOptions: {
                            zoom: zoom
                        },
                        pois: response.data.pois,
                        clusteringOptions,
                        infoBoxOptions: () => createInfoBoxStyle(),
                        poiStyles: () => createPoiStyles(),
                        onActivateMarker: (marker, api) => onActivateMarker(marker, api),
                        onDeactivateMarker: (marker, api) => onDeactivateMarker(marker, api)
                    });
                })
                    .catch(function (error) {
                        console.log('error', error);
                    });
            }

            googleMap.getApi(map).then((mapApi) => {
                let mapInstance = mapApi.getMapInstance();
                let location = map.getAttribute('data-map-bounds');

                if (location) {
                    let data = JSON.parse(location),
                        bounds = new google.maps.LatLngBounds(new google.maps.LatLng(data.sw.lat, data.sw.lng), new google.maps.LatLng(data.ne.lat, data.ne.lng));

                    mapInstance.fitBounds(bounds);
                }
            });
        });
    }
}

function createPoiStyles(){
    return {
        default: {
            default: {
                url: '/static/img/map/europapier.svg',
                size: new google.maps.Size(37, 43),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(18, 43)
            },
            active: {
                url: '/static/img/map/europapier-x.svg',
                size: new google.maps.Size(37, 43),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(18, 43)
            }
        },
        werbetechnik: {
            default: {
                url: '/static/img/map/werbetechnik.svg',
                size: new google.maps.Size(37, 43),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(18, 43)
            },
            active: {
                url: '/static/img/map/werbetechnik-x.svg',
                size: new google.maps.Size(37, 43),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(18, 43)
            }
        },
        mvh: {
            default: {
                url: '/static/img/map/mvh.svg',
                size: new google.maps.Size(37, 43),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(18, 43)
            },
            active: {
                url: '/static/img/map/mvh-x.svg',
                size: new google.maps.Size(37, 43),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(18, 43)
            }
        }
    };
}

function createInfoBoxStyle(){
    return {
        pixelOffset: new google.maps.Size((matchMedia('(max-width: 767px)').matches ? -145: -180), -50),
        boxStyle: {
            width: (matchMedia('(max-width: 767px)').matches ? "290px": "360px"),
            background: "#fff",
            padding: "0",
            borderRadius: "0"
        }
    };
}

function onActivateMarker(marker, mapApi) {
    if(marker.detailInfoBoxUrl) {

        if (marker.savedInfoBox) {
            mapApi.showInfoBoxByMarker(marker, marker.savedInfoBox);
        } else {
            let infoBoxPromise = mapApi.showInfoBoxByMarker(marker, `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`)

            let contentPromise = axios({
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: marker.detailInfoBoxUrl,
                responseType: 'json'
            });

            Promise.all([infoBoxPromise, contentPromise]).then(([infoBox, response]) => {
                infoBox.setContent(response.data.html);

                marker.savedInfoBox = response.data.html;
            });
        }
    }
}

function onDeactivateMarker(marker, mapApi) {
    mapApi.closeInfoBoxByMarker(marker);
}