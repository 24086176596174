import {onFind, initInScope, cleanUpInScope} from "@elements/init-modules-in-scope";
import {find, findAllIn, hasClass, closest, findIn, trigger, triggerWith, on, removeAttribute, setAttribute, addClass, removeClass} from '@elements/dom-utils';
import {getPrefixedDataSet} from '@elements/data-set-utils';
import fetch from "@elements/fetch";
import escapeHtml from "escape-html";
import {responseTracking} from "@elements/tracking";

const defaultOptions = {
    scrollOffset: 80,
    disableScroll: true
}

const defaultSelectors = {
    base: '.js-product-grid',
    content: '.js-product-grid__content',
    container: '.js-product-grid__container',
    link: '.js-product-grid__link',
    submitSection: '.js-product-grid__submit-section',
    submitSectionBtn: '.js-product-grid__submit-section-btn',
    loadMoreLink: '.js-product-grid__load-more-link',
    contentLink: '.js-product-grid__content-link',
    removeInput: '.js-product-grid__remove-input',
    error: '.js-product-grid__error',
    loading: '.js-product-grid__loading',
    input: '.js-product-grid__input',
    form: '.js-product-grid__form',
    hideWhenLoadPrevious: '.js-product-grid__hide-when-load-previous',
    hideWhenLoadNext: '.js-product-grid__hide-when-load-next',
    inputIsHidden: 'js-product-grid__input--hidden',
    inputIsHiddenUrl: 'js-product-grid__input--hidden-url',
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    createProductGrid(
        find(selectors.base),
        {...defaultOptions, ...options},
        {...defaultSelectors, ...selectors}
    );
}

export function createProductGrid(productGridElement, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...defaultSelectors,
    };

    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    if (!productGridElement) {
        return;
    }

    let newState = Object.assign({}, history.state, {
        productGridAjaxUrl: productGridElement.getAttribute('data-product-grid-ajax-url'),
        productGridInputState: getParams(productGridElement, selectors),
        productGridAdditionalInputState: getAdditionalInputState(productGridElement)
    });

    if (options.disableScrollRestoration && 'scrollRestoration' in history) {
        // in this case the location on the page is not restored when the user clicks the back button
        // this is useful if the load-more-button (js-product-grid__load-more-link) variant of the product grid is used
        history.scrollRestoration = 'manual';
    }

    history.replaceState(newState, document.title);


    window.addEventListener('popstate', (evt) => {
        if (evt.state.productGridAjaxUrl) {
            setInputState(productGridElement, evt.state.productGridInputState.filter(function (currentInput) {
                return currentInput.value !== '$$EMPTY$$'
            }), selectors);

            productGridElement.getAttribute('data-additional-input-state', evt.state.productGridAdditionalInputState);

            loadUrl(
                productGridElement,
                evt.state.productGridAjaxUrl,
                evt.state.productGridInputState,
                false,
                selectors,
                options
            ).catch((error, requestState) => {
                /*reset the input state; otherwise the url params and the form inputs would not be the same*/
                setInputState(productGridElement, evt.state.productGridInputState, selectors);
                console.error(error, requestState);
            });
        }
    });

    onFind(selectors.link, function (item) {
        on('click', function (evt) {
            console.log("clicked product grid link")
            let productGridElement = closest(selectors.base, item);
            let url = item.getAttribute('href') ? item.getAttribute('href') : item.getAttribute('data-product-grid-href');
            let ajaxUrl = item.getAttribute('data-product-grid-ajax-url') || url;
            evt.preventDefault();

            if (hasClass('js-product-grid__link--pagination', item)) {
                let result = productGridElement.querySelector('.js-product-grid__content');
                let offset = 210;
                window.scrollTo({
                    behavior: 'smooth',
                    top:
                        result.getBoundingClientRect().top -
                        document.body.getBoundingClientRect().top -
                        offset,
                });
            }

            productGridElement.getAttribute('data-additional-input-state', []);

            loadUrl(productGridElement, ajaxUrl, [], false, '', selectors, options).then(function () {
                triggerWith('product-grid.changed', [getInputState(productGridElement, selectors)], productGridElement);
                history.replaceState({
                    'productGridAjaxUrl': ajaxUrl,
                    'productGridInputState': getParams(productGridElement, selectors),
                    'productGridAdditionalInputState': []
                }, document.title);

                if (hasClass('js-product-grid__link--top', item)) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: 0,
                    });
                }
            });

            /* the state is replace with the real form state after the grid is done loading the content.
            * This is needed because we can't know the input state before the form is loaded*/
            history.pushState({
                    'productGridAjaxUrl': ajaxUrl,
                    'productGridInputState': [],
                    'productGridAdditionalInputState': []
                },
                document.title,
                url
            );

        }, item);
    });

    onFind(selectors.content, function (productGridContent) {
        productGridElement = closest(selectors.base, productGridContent);

        if (!productGridElement) {
            return;
        }

        let changedInputs = getChangeInputElements(productGridElement, selectors);
        changedInputs.map((input) => {
            const setInputs = () => {
                reloadGrid(closest(selectors.base, input), selectors, options);
                // change not submitted sections immediately
                setInputState(productGridElement, getInputState(productGridElement, selectors));
            };
            input.addEventListener('change', setInputs);
            return {
                cleanUp: () => {
                    input.removeEventListener('change', setInputs);
                }
            };
        });

        let submitButtons = findAllIn(selectors.submitSectionBtn, productGridElement);
        submitButtons.map((button) => {
            const submit = (evt) => {
                evt.preventDefault();
                let productGridElement = closest(selectors.base, button);
                updateSubmitSectionDataValues(closest(selectors.submitSection, button), selectors);
                reloadGrid(productGridElement, selectors, options);
            };

            button.addEventListener('click', submit);

            return {
                cleanUp: () => {
                    button.removeEventListener('click', submit);
                }
            };
        });

        let submitSections = findAllIn(selectors.submitSection, productGridElement);
        submitSections.map((section) => {
            if (section.getAttribute('data-auto-submit-range')) {
                on('range-slider.set', function (evt) {
                    let productGridElement = closest(selectors.base, section);
                    updateSubmitSectionDataValues(closest(selectors.submitSection, section), selectors);
                    reloadGrid(productGridElement, selectors, options);
                }, section);
            }

            if (matchMedia('(max-width: '+ section.getAttribute('data-product-grid-disable-submit-section') +'px)').matches) {
                return;
            }

            updateSubmitSectionDataValues(section, selectors);

            on('keydown', function (evt) {
                if (evt.keyCode === 13) {
                    let productGridElement = closest(selectors.base, section);
                    evt.preventDefault();
                    updateSubmitSectionDataValues(section, selectors);

                    reloadGrid(productGridElement, selectors, options);
                }
            }, section);
        });

        let contentLinks = findAllIn(selectors.contentLink, productGridElement);
        contentLinks.map((link) => {
            on('click', function (evt) {
                let productGridElement = closest(selectors.base, link);
                let url = link.getAttribute('href') ? link.getAttribute('href') : link.getAttribute('data-product-grid-href');
                let ajaxUrl = link.getAttribute('data-product-grid-ajax-url') || url;
                evt.preventDefault();

                loadUrl(productGridElement, ajaxUrl, [], true, '', selectors, options);
                history.pushState({
                        'productGridAjaxUrl': ajaxUrl,
                        'productGridInputState': getParams(productGridElement, selectors),
                        'productGridAdditionalInputState': getAdditionalInputState(productGridElement)
                    },
                    document.title,
                    url
                );
            }, link);
        });

        let loadMoreLinks = findAllIn(selectors.loadMoreLink, productGridElement);
        loadMoreLinks.map((link) => {
            on('click', function (evt) {
                let productGridElement = closest(selectors.base, link);

                let url = link.getAttribute('href');
                let ajaxUrl = link.getAttribute('data-product-grid-ajax-url') || url;
                evt.preventDefault();

                loadUrl(productGridElement, ajaxUrl, [], true, link.getAttribute('data-load-more-type'), selectors, options);
                history.replaceState({
                        'productGridAjaxUrl': ajaxUrl,
                        'productGridInputState': getParams(productGridElement, selectors),
                        'productGridAdditionalInputState': getAdditionalInputState(productGridElement)
                    },
                    document.title,
                    url
                );
            }, link);
        });

        let removeInputButtons = findAllIn(selectors.removeInput, productGridElement);
        removeInputButtons.map((button) => {
            on('click', function (evt) {
                let productGridElement = closest(selectors.base, button);
                let nameString = button.getAttribute('data-name');

                if (!nameString) {
                    console.error('Can\'t remove product grid inputs. data-name is not set on', this);
                    return;
                }

                evt.preventDefault();

                if (nameString === '*') {
                    removeInputs(productGridElement, getInputState(productGridElement, selectors).filter(function (currentInput) {
                        return currentInput.hidden !== true;
                    }), selectors);
                } else {
                    removeInputs(productGridElement, nameString.replace(/\s/g, '').split(',').map(function (name) {
                        return {
                            'name': name
                        };
                    }), selectors);
                }
            }, button);
        });
    });
}

export function reloadGrid(productGridElement, selectors = defaultSelectors, options = defaultOptions) {
    let url = getBaseUrl(productGridElement, selectors);
    let ajaxUrl = productGridElement.getAttribute('data-product-grid-ajax-url') || url;

    console.log("reload grid");

    let params = getParams(productGridElement, selectors);
    let visibleParams = params.filter(function (input) {
        return input.hideInUrl !== true;
    });

    loadUrl(productGridElement, ajaxUrl, params, false, '', selectors, options);

    history.pushState({
            'productGridAjaxUrl': ajaxUrl,
            'productGridInputState': params,
            'productGridAdditionalInputState': getAdditionalInputState(productGridElement)
        },
        document.title,
        addParamsToUrl(url, visibleParams)
    );

    triggerWith('product-grid.changed', [getInputState(productGridElement, selectors)], productGridElement);
}

let pendingRequest,
    cache = [];

function loadUrl(productGridElement, ajaxUrl, params = [], loadContentOnly = false, insertMode = '', selectors = defaultSelectors, options = defaultOptions) {
    params = [].concat(params);

    console.log("loadUrl");

    if (pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    if(!options.disableScroll) {
        if (insertMode !== 'append') {
            if (window.pageYOffset > productGridElement.offsetTop) {
                window.scrollTo({
                    top: productGridElement.offsetTop - options.scrollOffset,
                    behavior: 'smooth'
                });
            }
        }
    }

    if (loadContentOnly) {
        params.push({
            name: 'contentOnly',
            value: 1
        });
    }

    params.push({
        name: '_original_href',
        value: getBasePath(productGridElement, selectors)
    });

    let cacheResult = cache.filter(function(cacheEntry) {
        return cacheEntry.url === addParamsToUrl(ajaxUrl, params)
    });

    if (cacheResult.length) {
        setResults(cacheResult[0].content, productGridElement, loadContentOnly, '', selectors);

        if (cacheResult[0].meta && cacheResult[0].meta.title) {
            document.title = cacheResult[0].meta.title;
        }

        let deferred = Promise.resolve(null, [cacheResult[0].content]);
        return deferred;
    } else {
        addClass('is-loading', productGridElement);
        let loadingElement = findIn(selectors.loading, productGridElement);
        removeAttribute('hidden', loadingElement);

        pendingRequest = fetch(addParamsToUrl(ajaxUrl, params), {
            method: 'GET'
        });
        
        responseTracking(pendingRequest);

        pendingRequest.then((response) => {
            return response.clone().json()
        }).then((response) => {
            if (response.success) {
                setResults(response.html, productGridElement, loadContentOnly, insertMode, selectors);

                cache.push({
                    url: addParamsToUrl(ajaxUrl, params),
                    content: response.html,
                    meta: {
                        title: response.meta && response.meta.title ? response.meta.title : null
                    }
                });

                if (cache.length > 40) {
                    cache.shift();
                }

                if (response.meta && response.meta.title) {
                    document.title = response.meta.title;
                }

                trigger('product-grid.success', productGridElement);
                // productGridElement.trigger('product-grid.success');
            }

            pendingRequest = null;
            removeClass('is-loading', productGridElement);
            setAttribute('hidden', true, loadingElement);

        }).catch((error, requestState) => {
            if ((!error || error.name !== 'AbortError') // native fetch abort
                && requestState !== 'abort') { // jquery abort
                console.error(error);
                if(findIn(selectors.error, productGridElement)){
                    removeAttribute('hidden', findIn(selectors.error, productGridElement));
                }

                pendingRequest = null;
                removeClass('is-loading', productGridElement);

                setAttribute('hidden', true, loadingElement);

                trigger('product-grid.failed', productGridElement);
                // productGridElement.trigger('product-grid.failed');
            }
        });

        return pendingRequest;
    }
}

function setResults(data, productGridElement, loadContentOnly, insertMode = '', selectors = defaultSelectors) {

    console.log("setResults");

    if (insertMode) {
        let containerElement = findIn(selectors.content, productGridElement);
        let responseContent = data;
        let elsToHide;

        cleanUpInScope(containerElement);

        if (insertMode === 'prepend') {
            // hide the load-previous-button and sorting-form
            elsToHide = findAllIn(selectors.hideWhenLoadPrevious, productGridElement);
            elsToHide.map((element) => {
                setAttribute('hidden', true, element);
            });

            containerElement.innerHTML = responseContent + containerElement.innerHTML;
        } else if (insertMode === 'append') {
            // hide the load-next-button
            elsToHide = findAllIn(selectors.hideWhenLoadNext, productGridElement);
            elsToHide.map((element) => {
                setAttribute('hidden', true, element);
            });

            containerElement.innerHTML += responseContent;
        }


        initInScope(containerElement);
    } else {
        let containerElement = loadContentOnly
            ? findIn(selectors.content, productGridElement)
            : findIn(selectors.container, productGridElement);

        cleanUpInScope(containerElement);
        setInnerHTML(containerElement, data);
        initInScope(containerElement);
    }
}

function setInnerHTML(elm, html) {
    elm.innerHTML = html;

    Array.from(elm.querySelectorAll("script"))
        .forEach( oldScriptEl => {
            const newScriptEl = document.createElement("script");

            const scriptText = document.createTextNode(oldScriptEl.innerHTML);
            newScriptEl.appendChild(scriptText);

            oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
        });
}

// todo add support to add functionality to this function
function serializeInputElements(inputs, selectors = defaultSelectors) {
    let inputState = [];

    inputs.map((inputElement) => {
        let currentInputState = serializeInput(inputElement);

        if (currentInputState && currentInputState.value !== '') {
            if (hasClass(selectors.inputIsHidden.replace(/\./g, ""), inputElement)) {
                currentInputState.hidden = true;
            }

            if (hasClass(selectors.inputIsHiddenUrl.replace(/\./g, ""), inputElement)) {
                currentInputState.hidden = true;
                currentInputState.hideInUrl = true;
            }

            currentInputState.text = escapeHtml(getText(inputElement));

            currentInputState = {
                ...getPrefixedDataSet('product-grid-input', inputElement),
                ...currentInputState
            };

            inputState.push(currentInputState);
        }
    });

    return inputState
}

export function getInputState(productGridElement, selectors = defaultSelectors) {
    let inputState = serializeInputElements(getChangeInputElements(productGridElement, selectors), selectors);

    findAllIn(selectors.submitSection, productGridElement).map((section) => {
        if (section.getAttribute('data-product-grid-input-state')) {
            inputState = inputState.concat(JSON.parse(section.getAttribute('data-product-grid-input-state')));
        }
    });

    inputState = inputState.concat(getAdditionalInputState(productGridElement));

    return inputState;
}

function getEmptyParams(productGridElement, selectors = defaultSelectors) {
    return getEmptyAbleInputNames(productGridElement, selectors).filter(function (name) {
        return !(getInputState(productGridElement, selectors).filter(function (currentInput) {
            return currentInput.name === name;
        })[0]);
    }).map(function (name) {
        return {
            'name': name,
            'value': '$$EMPTY$$',
            'hidden': true
        }
    });
}

function getParams(productGridElement, selectors = defaultSelectors) {
    return getInputState(productGridElement, selectors).concat(getEmptyParams(productGridElement, selectors));
}

function setInputState(productGridElement, inputState, selectors = defaultSelectors) {
    let allInputs = getInputElements(productGridElement, selectors);

    // let test = allInputs.filter(function (input) {
    //     return input.type !== 'radio' && input.type !== 'checkbox';
    // });

    allInputs.filter(function (input) {
        return input.type !== 'radio' && input.type !== 'checkbox';
    }).forEach((item) => {
        let currentInput = item;
        let result = findInInputState(inputState, currentInput.getAttribute('name'));
        let prevValue = currentInput.value;

        if (result && result.value !== '$$EMPTY$$') {
            currentInput.value = result.value;
        } else {
            currentInput.value = '';
        }

        if (prevValue !== currentInput.value) {
            trigger('changed', currentInput);
            trigger('product-grid.input-changed', currentInput);
            // currentInput.trigger('changed').trigger('product-grid.input-changed');
        }
    });

    allInputs.filter(function (input) {
        return input.type === 'radio' || input.type === 'checkbox';
    }).forEach((item) => {
        let currentInput = item;
        let result = findInInputState(inputState, currentInput.getAttribute('name'), currentInput.getAttribute('value'));
        let wasChecked = currentInput.checked;
        let shouldBeChecked = !!result && result.value !== '$$EMPTY$$';

        currentInput.checked = shouldBeChecked;

        if (shouldBeChecked !== wasChecked) {
            trigger('changed', currentInput);
        }
    });

    if (findAllIn(selectors.submitSection, productGridElement)) {
        findAllIn(selectors.submitSection, productGridElement).map((section) => {
            if (matchMedia('(max-width: '+ section.getAttribute('data-product-grid-disable-submit-section') +'px)').matches) {
                return;
            }

            updateSubmitSectionDataValues(section, selectors);

            console.log("updateSubmitSectionDataValues");
        });
    }

    /* do not reset the remember-collapse checkbox */
    let productGridForm = findIn(selectors.form, productGridElement);
    let rememberCollapse = findIn('.js-remember-collapse__input', productGridForm);
    rememberCollapse.checked = true;

    triggerWith('product-grid.changed', [getInputState(productGridElement, selectors)], productGridElement);
}

function findInInputState(inputState, name, value) {
    return inputState.filter(function (currentInputState) {
        if (typeof value !== 'undefined') {
            return currentInputState.name === name && currentInputState.value === value;
        } else {
            return currentInputState.name === name;
        }
    })[0];
}

function addParamsToUrl(url, params) {
    let urlWithParams = url;
    if (params && params.length) {
        urlWithParams += urlWithParams.indexOf('?') < 0 ? '?' : '&';
        urlWithParams += params.map(function(param) {
            return param.name + '=' + encodeURIComponent(param.value)
        }).join('&');
    }

    return urlWithParams;
}

function getText(inputElement) {
    let text = inputElement.getAttribute('data-text') || inputElement.value;

    if (inputElement.tagName === 'SELECT') {
        let selectedOption = inputElement.options[inputElement.selectedIndex];

        if (selectedOption) {
            text = selectedOption.getAttribute('data-text') || selectedOption.text;
        }
    }

    if (inputElement.getAttribute('data-text-prefix')) {
        text = inputElement.getAttribute('data-text-prefix') + text;
    }

    if (inputElement.getAttribute('data-text-suffix')) {
        text += inputElement.getAttribute('data-text-suffix');
    }

    return text;
}

function getInputElements(scope, selectors = defaultSelectors) {
    return findAllIn(
        selectors.input + ', ' +
        selectors.form + ' input, ' +
        selectors.form + ' select, ' +
        selectors.submitSection + ' input, ' +
        selectors.submitSection + ' select'
        , scope);
}

/* get all inputs that trigger a reaload on change*/
function getChangeInputElements(scope, selectors = defaultSelectors) {
    let inputs = findAllIn(
        selectors.input + ', ' +
        selectors.form + ' input, ' +
        selectors.form + ' select', scope
    );
    inputs = inputs.filter((input) => {
        if (closest(selectors.submitSection, input) && closest(selectors.submitSection, input).getAttribute('data-product-grid-disable-submit-section') && matchMedia('(max-width: '+ closest(selectors.submitSection, input).getAttribute('data-product-grid-disable-submit-section') +'px)').matches) {
            return input
        } else {
            return !closest(selectors.submitSection, input)
        }
    });

    return inputs;
}

/* EmptyAble = elements that use the $$empty$$ param*/
function getEmptyAbleInputElements(scope, selectors = defaultSelectors) {
    return getInputElements(scope, selectors).filter((input) => input.getAttribute('data-product-grid-use-empty-param') || closest('[data-product-grid-use-empty-param]', input) ? input : false)
}

export function removeInput(productGridElement, name, value, selectors = defaultSelectors, options = defaultOptions) {
    let newState = getInputState(productGridElement, selectors).filter(function (currentInputState) {
        if (typeof value !== 'undefined') {
            return currentInputState.name !== name || currentInputState.value !== value;
        } else {
            return currentInputState.name !== name;
        }
    });

    let additionalInputState = getAdditionalInputState(productGridElement);
    additionalInputState = additionalInputState.filter(function (currentInputState) {
        if (typeof value !== 'undefined') {
            return currentInputState.name !== name || currentInputState.value !== value;
        } else {
            return currentInputState.name !== name;
        }
    });

    productGridElement.getAttribute('data-additional-input-state', additionalInputState);

    setInputState(productGridElement, newState, selectors);
    reloadGrid(productGridElement, selectors, options);
}

export function removeInputs(productGridElement, inputsToRemove, selectors = defaultSelectors, options = defaultOptions) {
    let newState = getInputState(productGridElement, selectors).filter(function (currentInputState) {
        let shouldBeRemoved = !!(inputsToRemove.filter(function (inputToRemove) {
            if (typeof inputToRemove.value !== 'undefined') {
                return currentInputState.name === inputToRemove.name && currentInputState.value === inputToRemove.value;
            } else {
                return currentInputState.name === inputToRemove.name;
            }
        })[0]);

        return !shouldBeRemoved;
    });

    setInputState(productGridElement, newState, selectors);
    reloadGrid(productGridElement, selectors, options);
}

export function setInput(productGridElement, name, value, selectors = defaultSelectors, options = defaultOptions) {
    let newState = getInputState(productGridElement, selectors).map(function (currentInputState) {
        if (currentInputState.name === name) {
            return Object.assign({}, currentInputState, {
                value: value
            });
        }

        return currentInputState;
    });

    setInputState(productGridElement, newState, selectors);
    reloadGrid(productGridElement, selectors, options);
}

function getBaseUrl(productGridElement, selectors = defaultSelectors) {
    return location.origin + getBasePath(productGridElement, selectors);
}

function getBasePath(productGridElement, selectors = defaultSelectors) {
    if (location.search) {
        let inputNames = getAllInputNames(productGridElement, selectors);
        let additionalInputNames = getAdditionalInputState(productGridElement).map(x => x.name);
        let parmsArray = getUrlParams().filter(function (param) {
            return (
                inputNames.indexOf(param.name) < 0
                && additionalInputNames.indexOf(param.name) < 0
            ) && param.name !== 'page';
        });

        return addParamsToUrl(location.pathname, parmsArray);
    } else {
        return location.pathname;
    }
}

function getUrlParams() {
    return location.search
        ? decodeURIComponent(location.search)
            .substr(1)
            .split("&")
            .map(function (paramString) {
                let paramArr = paramString.split('=');
                return {
                    name: paramArr[0],
                    value: paramArr[1]
                }
            })
        : [];
}

function getAllInputNames(productGridElement, selectors = defaultSelectors) {
    return getNamesOfInputs(getInputElements(productGridElement, selectors));
}

/* EmptyAble = elements that use the $$empty$$ param*/
function getEmptyAbleInputNames(productGridElement, selectors = defaultSelectors) {
    return getNamesOfInputs(getEmptyAbleInputElements(productGridElement, selectors));
}

function getNamesOfInputs(inputs) {
    return inputs.reduce(function (names, input) {
        let currentName = input.getAttribute('name');
        if (currentName && names.indexOf(currentName) < 0) {
            names.push(currentName);
        }
        return names;
    }, []);
}

function updateSubmitSectionDataValues(productGridSection, selectors = defaultSelectors) {
    productGridSection.setAttribute('data-product-grid-input-state', JSON.stringify(serializeInputElements(findAllIn('input, select', productGridSection), selectors)));
}

function getAdditionalInputState(productGridElement) {
    return productGridElement.getAttribute('data-additional-input-state') || [];
}

export function setValue(productGridElement, name, value, text) {
    setValues(productGridElement, [{
        name: name,
        value: value,
        text: text
    }]);
}

export function setValues(productGridElement, values) {
    let inputState = getAdditionalInputState(productGridElement);

    values.forEach(function (currentValue) {
        let existingValue = (inputState.filter(function (x) {
            return currentValue.name === x.name;
        })[0]);

        if (existingValue) {
            existingValue.value = currentValue.value;
        } else {
            inputState.push(currentValue);
        }
    });

    productGridElement.getAttribute('data-additional-input-state', inputState);
    reloadGrid(productGridElement);
}

function serializeInput(input) {

    let inputData;

    if (input.name && !input.disabled && input.type != 'file' && input.type != 'reset' && input.type != 'submit' && input.type != 'button') {
        if (input.type == 'select-multiple') {
            for (let i = 0; i < input.options.length; i++) {
                if (input.options[i].selected)
                    inputData = {name: input.name, value: input.options[i].value};
            }
        } else if ((input.type != 'checkbox' && input.type != 'radio') || input.checked) {
            inputData = { name: input.name, value: input.value };
        }
    }

    return inputData;
}