import {onFind} from "@elements/init-modules-in-scope";
import {on, closest, findAllIn, trigger} from "@elements/dom-utils";

const selectors = {
    base: '.js-check-all',
    container: '.js-check-all__container',
    child: '.js-check-all__check-input'
};

// the input with the base class un-/checks all other inputs with the child class that are in the container
export function init() {
    onFind(selectors.base, (input) => {
        on('change', (evt) => {
            let container = closest(selectors.container, input);
            const checked = input.checked;

            let inputsToChange = findAllIn(selectors.child, container);
            inputsToChange.forEach((inputToChange) => {
                inputToChange.checked = checked;
                trigger('change', inputToChange);
            })
        }, input);
    });
}
