import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass, findIn} from "@elements/dom-utils";


export function init() {
    onFind('.js-hero', function (hero) {
        let heroDesktop = findIn('.js-hero__desktop', hero);
        let heroMobile = findIn('.js-hero__mobile', hero);
        let heroVideo = findIn('.js-hero__video', hero);
        let viewportWidth = document.documentElement.clientWidth;

        if (heroVideo) {
            addClass('active', heroVideo);
            removeClass('active', heroDesktop);
            removeClass('active', heroMobile);
        }else{
            if (heroDesktop && heroMobile) {
                if (viewportWidth < 768) {
                    addClass('active', heroMobile);
                    removeClass('active', heroDesktop);
                } else {
                    addClass('active', heroDesktop);
                    removeClass('active', heroMobile);
                }
            }else if (heroDesktop && !heroMobile) {
                addClass('active', heroDesktop);
            }
        }
    });
}